import React from 'react'
import Nav from './Nav'
import ContactBottom from './ContactBottom'

function Cpl() {
  return (
    <>
    <Nav />
    <div className='App'>
      <div className='ppldiv'>
      <h1>Coming Soon</h1>
      </div>
      </div>
<ContactBottom/>
    </>
  )
}

export default Cpl